const FirebaseConfig = {
  databaseURL: 'https://emilus.firebaseio.com',
  apiKey: "AIzaSyDNlBpoVNu-WKLJsHRGDVBNkkLweVhT6rk",
  authDomain: "smenu-dev-49391.firebaseapp.com",
  projectId: "smenu-dev-49391",
  storageBucket: "smenu-dev-49391.appspot.com",
  messagingSenderId: "909594262293",
  appId: "1:909594262293:web:67ec3b6df57bc8e922871c",
  measurementId: "G-RKJT7PFW4V"
};

export default FirebaseConfig

